import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Button, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { hasAccess, isAllowedAction } from "../libs/rbac";
import moment from "moment-timezone";
import { API } from "aws-amplify";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing.unit,
  },
  loadMoreBtns: {
    display: "flex",
    justifyContent: "center",
    bottom: 0,
  },
  loadMoreBtn: {
    margin: "1rem",
    marginLeft: 0,
  },
});

class Home extends Component {

  constructor() {
    super();
    this.state = {
      activities: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const { currUser } = this.props;
    if (isAllowedAction(currUser.userGroup)) {
      this.getRecentActivities();
    }
  }

  async getRecentActivities() {
    try {
      this.setState({ isLoading: true });
      const { activities } = await API.get(
        "v2",
        `marketplace/activities/by/recent?limit=20`
      );

      this.setState({
        isLoading: false,
        activities
      });
    } catch (e) {
      this.setState({ isLoading: false });
      alert(e);
    }
  }

  menus() {
    return [
      // { path: "/orders", name: "Orders" }, // Legacied as of: April 24, 2023
      { path: "/search_orders", name: "Search Orders" },
      {
        path: "/orders/create/?step=select-game",
        name: "Create New Order",
      },
      { path: "/seats", name: "Seats" },
      { path: "/seats-by-seller", name: "Seats by Seller" },
      { path: "/sell-requests", name: "Sell Requests" },
      { path: "/sell-request-approval", name: "Sell Request Approval" },
      { path: "/reset-user-password", name: "Reset User Password" },
      { path: "/sms", name: "Send SMS for Game" },
      { path: "/min-price-per-game", name: "Min Price Per Game" },
      { path: "/coupons", name: "Coupons" },
      { path: "/game-page", name: "Game Page" },
      { path: "/ticket-evolution", name: "Ticket Evolution" },
      { path: "/emergency-mode", name: "Emergency Mode" },
      { path: "/user-support", name: "User Support" },
      { path: "/blocked-users", name: "Blocked Users" },
      { path: "/seller", name: "Seller" },
      { path: "/activities", name: "Activities" },
      { path: "/seller-specific", name: "Seller Specific" },
      { path: "/setting", name: "Setting" },
      { path: "/gameday-notification", name: "Gameday Email Notification" },
      { path: "/unpaid-games", name: "Unpaid Games" },
    ];
  }

  renderMenus(userGroup) {
    const { classes } = this.props;

    return this.menus().map((menu) => {
      if (hasAccess(menu.path, userGroup)) {
        return (
          <Button
            key={menu.path}
            component={Link}
            to={menu.path}
            variant="outlined"
            color="primary"
            className={classes.button}
          >
            {menu.name}
          </Button>
        );
      }

      return "";
    });
  }

  renderData() {
    const { activities } = this.state;
    if (activities.length > 0) {
      return (
        activities.map((activity, index) => (
          <TableRow key={index}>
            <TableCell>
              {activity.email}
            </TableCell>
            <TableCell>
              {activity.activityType}
            </TableCell>
            <TableCell>
              {activity.description}
            </TableCell>
            <TableCell>
              {moment
                .tz(Number(activity.createdAt), "America/Edmonton")
                .format("MMM DD, YYYY hh:mm A")}
            </TableCell>
          </TableRow>
        ))
      )
    } else {
      return (
        <TableRow>
          <TableCell align="center" colSpan="4">
            <Typography variant="subheading" style={{ 'textAlign': 'center' }}>There is no data</Typography>
          </TableCell>
        </TableRow>
      )
    }
  }

  render() {
    const { classes, currUser } = this.props;
    const { userGroup } = currUser;

    return (
      <div className={classes.root} id="Home">
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="display2">Home</Typography>
          </Grid>
          <Grid item xs={12}>
            {this.renderMenus(userGroup)}
          </Grid>
          {isAllowedAction(userGroup) && (
            <Grid item xs={12}>
              <Typography variant="display1" style={{ 'marginTop': '20px' }}>Latest Activities</Typography>
              <Paper>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell component="th">User</TableCell>
                      <TableCell component="th">Type</TableCell>
                      <TableCell component="th">Description</TableCell>
                      <TableCell component="th">Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.isLoading ? (
                      <TableRow>
                        <TableCell align="center" colSpan="4">
                          <Typography variant="subheading" style={{ 'textAlign': 'center' }}>Loading...</Typography>
                        </TableCell>
                      </TableRow>
                    ) : this.renderData()}
                  </TableBody>
                </Table>
              </Paper>
              <div className={classes.loadMoreBtns}>
                <Button
                  disabled={this.state.isLoading}
                  variant="contained"
                  color="secondary"
                  className={classes.loadMoreBtn}
                  onClick={() => this.props.history.push("/activities")}
                >
                  See More
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
