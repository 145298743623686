import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Orders from "./containers/Orders";
import SearchOrders from "./containers/SearchOrders";
import NotFound from "./containers/NotFound";
import Seats from "./containers/Seats";
import SeatDetails from "./containers/SeatDetails";
import AddSeats from "./containers/AddSeats";
import Login from "./containers/Login";
import SellRequests from "./containers/SellRequests";
import SellRequest from "./containers/SellRequest";
import UpdateSeat from "./containers/UpdateSeat";
import SellerSeats from "./containers/SellerSeats";
import ResetUserPassword from "./containers/ResetUserPassword";
import SendGameSMS from "./containers/SendGameSMS";
import NewOrder from "./containers/NewOrder";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import MinPricePerGame from "./containers/MinPricePerGame";
import Coupons from "./containers/Coupons";
import Coupon from "./containers/Coupon";
import NewCoupon from "./containers/NewCoupon";
import GamePage from "./containers/GamePage";
import TicketEvo from "./containers/TicketEvo";
import TicketEvoTop from "./containers/TicketEvoTop";
import EmergencyMode from "./containers/EmergencyMode";
import UserSupport from "./containers/UserSupport";
import BlockedUsers from "./containers/BlockedUsers";
import SellRequestApproval from "./containers/SellRequestApproval";
import Seller from "./containers/Seller";
import Activities from "./containers/Activities";
import SellerSpecific from "./containers/SellerSpecific";
import Setting from "./containers/Setting";
import GamedayNotification from "./containers/GamedayNotification";
import UnpaidGames from "./containers/UnpaidGames";

export default ({ childProps }) => (
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute
      path="/orders"
      exact
      component={Orders}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/search_orders"
      exact
      component={SearchOrders}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/game-page"
      exact
      component={GamePage}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/coupons"
      exact
      component={Coupons}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/coupons/:id"
      exact
      component={Coupon}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/new-coupon"
      exact
      component={NewCoupon}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/orders/create"
      exact
      component={NewOrder}
      props={childProps}
    />
    <UnauthenticatedRoute
      path="/login"
      exact
      component={Login}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seats"
      exact
      component={Seats}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seats-by-seller"
      exact
      component={SellerSeats}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seats/:id"
      exact
      component={SeatDetails}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seats/:id/update"
      exact
      component={UpdateSeat}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/new-seats"
      exact
      component={AddSeats}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/sell-requests"
      exact
      component={SellRequests}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/sell-requests/:id"
      exact
      component={SellRequest}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/reset-user-password"
      exact
      component={ResetUserPassword}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/sms"
      exact
      component={SendGameSMS}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/min-price-per-game"
      exact
      component={MinPricePerGame}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/ticket-evolution"
      exact
      component={TicketEvo}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/emergency-mode"
      exact
      component={EmergencyMode}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/ticket-evolution-top"
      exact
      component={TicketEvoTop}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/user-support"
      exact
      component={UserSupport}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/blocked-users"
      exact
      component={BlockedUsers}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/sell-request-approval"
      exact
      component={SellRequestApproval}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seller"
      exact
      component={Seller}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/activities"
      exact
      component={Activities}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/seller-specific"
      exact
      component={SellerSpecific}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/setting"
      exact
      component={Setting}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/gameday-notification"
      exact
      component={GamedayNotification}
      props={childProps}
    />
    <AuthenticatedRoute
      path="/unpaid-games"
      exact
      component={UnpaidGames}
      props={childProps}
    />

    <Route component={NotFound} />
  </Switch>
);
